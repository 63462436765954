<!--
// Copyright © 2022 Hardcore Engineering Inc.
//
// Licensed under the Eclipse Public License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License. You may
// obtain a copy of the License at https://www.eclipse.org/legal/epl-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//
// See the License for the specific language governing permissions and
// limitations under the License.
-->
<script lang="ts">
  import { Doc, generateId, Ref } from '@hcengineering/core'
  import { ViewContext } from '@hcengineering/view'
  import { onDestroy } from 'svelte'
  import { ContextStore, contextStore } from '../context'

  interface ViewContextWithId extends ViewContext {
    id?: Ref<Doc>
  }

  export let context: ViewContext

  const id = generateId()

  onDestroy(() => {
    contextStore.update((cur) => {
      const contexts = cur.contexts as ViewContextWithId[]
      const pos = contexts.findIndex((it) => it.id === id)
      if (pos === -1) {
        return cur
      }
      return new ContextStore(contexts.slice(0, pos))
    })
  })

  $: {
    contextStore.update((cur) => {
      const contexts = cur.contexts as ViewContextWithId[]
      const pos = contexts.findIndex((it) => it.id === id)
      const newCur: ViewContextWithId = {
        id,
        mode: context.mode,
        application: context.application ?? contexts[(pos !== -1 ? pos : contexts.length) - 1]?.application
      }
      if (pos === -1) {
        return new ContextStore([...contexts, newCur])
      }
      return new ContextStore(contexts.map((it) => (it.id === id ? newCur : it)))
    })
  }
</script>
